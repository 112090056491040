
body {
    display: block;
}
body.empty .body-background {
    background: #387fb3;
    background: -webkit-linear-gradient(45deg, #b1dcfb 0%, #669baf 50%, #387fb3 100%);
    background: linear-gradient(45deg, #b1dcfb 0%, #669baf 50%, #387fb3 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b1dcfb', endColorstr='#387fb3', GradientType=1);
    max-width: 1600px;
    margin: 0 auto;
    display: block;
}
body.empty .payment-footer {
    padding: 0;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 0;
    color: #8694a7;
}
.body-background {
    width: 100%;
    min-height: 100%;
    margin: 0 auto;
    max-width: 1600px;
    background-color: #fff;
    position: relative;
}

.animation_top{
    height: 423px;
}

.horizontal-line {
    background: #cae8df;
    height: 2px;
    width: 110px;
    margin: 0px auto;
    margin-top: 22px;
    margin-bottom: 22px;
}

.title-header{
    font-family: 'Baloo Tamma', cursive;
    color: #fff;
    margin-top: 60px;
    font-size: 28px;
    line-height: 40px;
}

.card.card-login .card-content {
    padding: 25px 25px;
}
.card.card-login {
    border-radius: 4px;
}

section.page-product-hero, section.page-front{
    padding-top: 0;
    padding-bottom: 0;
}
.page-product-hero {
    max-height: 350px;
    min-height: 350px;
    position: relative;
    margin-top: 0px;
    border: 0;
    background: #387fb3;
    background: -webkit-linear-gradient(45deg, #b1dcfb 0%, #669baf 50%, #387fb3 100%);
    background: linear-gradient(45deg, #b1dcfb 0%, #669baf 50%, #387fb3 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b1dcfb', endColorstr='#387fb3', GradientType=1);
}

.page-front{
    max-height: 600px;
    min-height: 600px;
    position: relative;

    margin-top: 0px;
    border: 0;
    background: #387fb3;
    background: -webkit-linear-gradient(45deg, #b1dcfb 0%, #669baf 50%, #387fb3 100%);
    background: linear-gradient(45deg, #b1dcfb 0%, #669baf 50%, #387fb3 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b1dcfb', endColorstr='#387fb3', GradientType=1);
}



.page-product-hero nav, .page-front nav{
    background-color: transparent;
}



@media only screen and (max-width: 850px) {
    .page-product-hero{
        min-height: 250px;
    }
    .animation_top{
        display: none;
    }
}
