html, body {
    height: 100%;
}
body{
    background-color: #fff;
    font-family: 'Montserrat', Geneva, sans-serif;
}
body {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

#wrapper-global{
    flex: 1;
}

#page-content-wrapper {
    width: 100%;
    padding: 1.25em;
    padding-top: 20px;
    border-top: 1px solid #D2D1D1;
}

.contact_from_phonenumber .bootstrap-tagsinput {
    min-height: 90px;
    margin-bottom: 0px;
    width: 100%;
}

.panel-danger-alt-solid .panel-heading {
    background-color: #e26a6a;
    color: #fff;
}

.panel-danger-alt-solid .panel-body {
    background-color: #e26a6a;
    color: #fff;
    min-height: 80px;
}


.animated-short-delay {
    animation-delay: 1s;
}


.animated.slideInDown {
    -webkit-animation-duration: .2s;
    animation-duration: .2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.separator {
    position: relative;
    clear: both;
    margin: 0 15px 20px 0;
    width: 100%;
    height: 10px;
}
.separator-h {
    position: absolute;
    top: 50%;
    left: 10%;
    height: 0;
    width: 80%;
    border-top: 1px solid #ddd;
}

.separator-h i {
    position: absolute;
    top: -4px;
    left: 50%;
    font-size: 25px;
    height: 25px;
    width: 50px;
    margin-top: -8px;
    margin-left: -25px;
    text-align: center;
    background-color: #fff;
    color: #d2d2d2;
}

.bootstrap-tagsinput {
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: none;
    display: inline-block;
    padding: 4px 6px;
    margin-bottom: 10px;
    color: #555;
    vertical-align: middle;
    border-radius: 2px;
    width: 100%;
    max-width: 100%;
    line-height: 10px;
    height: auto;
}

.hideShowPassword-toggle, button.hideShowPassword-toggle:focus{
    position: absolute;
    right: 8px;
    top: 50%;
    margin-top: 7px;
    display: block;
    background-color: #fffffff2;
    border: 2px solid #ccc;
    border-radius: 5px;
}

.bootstrap-tagsinput .tag.label {
    border-radius: 40px;
    padding: 1px 6px;
    line-height: 25px;
    font-weight: normal;
    font-size: 13px;
    margin-top: 5px;
    display: inline-block;
}

.bootstrap-tagsinput .tag {
    margin-right: 2px;
    color: white;
}

.bootstrap-tagsinput .label-primary {
    background-color: #3eb0ad;
}
.bootstrap-tagsinput .label {
    display: inline;
    padding: .2em .6em .3em;
    font-size: 75%;
    font-weight: bold;
    line-height: 1;
    color: #ffffff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
}

.bootstrap-tagsinput .browserForm.with-no-border{
    border:none;
}

.bootstrap-tagsinput input:not([type]):focus:not([readonly]).with-no-border,
.bootstrap-tagsinput input[type=text]:not(.browser-default):focus:not([readonly]).with-no-border
{
    border-bottom: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

input[type=text].date-input{
    padding: 0 10px;
    border: 1px solid #ddd;
    height: 36px;
    border-radius: 3px;
    font-size: 15px;
    font-size: 15px;
    width: 140px;
}
input[type=text].date-input:focus {
    border-bottom: 1px solid #ddd;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.text-success {
    color: #31a15c;
}
.bigger-text {
    font-weight: 200;
    font-size: 4em;
    line-height: 1em;
}
.text-transparent {
    opacity: .5!important;
}


h2.resume{
    font-size: 27px;
    line-height: 1.5em;
    color: #999;
}

.dropdown-menu {
    z-index: 999;
}

.btn, .btn-large, .btn-flat{
    text-transform: lowercase;

}
.btn::first-letter, .btn-large::first-letter, .btn-flat::first-letter{
    text-transform: uppercase;

}
.row .col.no-padding-right{
    padding-right: 0;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    list-style: none;
    font-size: 13px;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    background-clip: padding-box;
}
.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0,0,0,0.15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0,0,0,0.175);
    box-shadow: 0 6px 12px rgba(0,0,0,0.175);
}






main,#wrapper {
    flex: 1 0 auto;
}
body.back{
    background-color: #fbfbfb;
}
body.grey-bg{
    background-color: #f8f8f8;
}
body.bg-med {
    background-color: #eaebee;
}
nav{
    box-shadow: none;
}

nav {
    color: #fff;
    background-color: #387fb3;
}
.navbar-fixed {
    height: 100%;
}


.container-full{
    width: 100%;
}

nav ul a.menu_master {
    height: 70px;
    line-height: 70px;
    margin-top: 0px;
    font-size: 12px;
    padding: 0 30px;
}
nav ul a.menu_master i {
    line-height: 35px;
    height: 35px;
}

code.prettyprint, pre.prettyprint{
    border-radius:0;
}
/*[type="checkbox"]:not(:checked), [type="checkbox"]:checked {
     position: relative;
     left: 0;
     opacity: 1;
}*/

.green2{
    background-color: #00b6b7;
}

iframe{
    border: none;
}

.btn-large i {
    font-size: 1rem;
}

nav i, nav [class^="mdi-"], nav [class*="mdi-"], nav i.material-icons {
    display: block;
    font-size: 0.8rem;
}


.tabs .indicator {
    position: absolute;
    bottom: 0;
    height: 48px;
    background-color: transparent;
    will-change: left, right;
    border: 1px solid #ffc0be;
    border-radius: 60px;
}

#navigation{
    height: 70px;
}
.with-margin-bottom-30{
    margin-bottom: 30px;
}

h1.litle-title{
    font-size: 33px;
    margin-bottom: 0;
}
h2.litle-title{
    font-size: 21px;
    line-height: 28px;
    margin-bottom: 0;
    color: #5ea58e;
}

#search_number h1.litle-title{
    font-size: 24px;
    margin-top: 0;
}

.row.margin-bottom-10{
    margin-bottom: 10px;
}

.toolbar-title{
    margin: 0 0 0;
}
.toolbar-title .btn{
    margin: 10px 0 10px;
}

#window-dialog-container{
    transform: translate3d(20px, 20px, 0px);
}

/*.picker--opened .picker__frame {
    top: 150px;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    -moz-opacity: 1;
    opacity: 1;
}
.picker__day--selected, .picker__day--selected:hover, .picker--focused .picker__day--selected {
    border-radius: 2px;
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    background-color: #26a69a;
    color: #ffffff;
}*/

nav.grey-bgcolor{
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    background-color: #f8f8f8;
}
nav.grey-bgcolor a{
    color: #000;
}

#navigation_iframe{
    height: 60px;
 }
#navigation_iframe nav .brand-logo{
    position: relative;
    font-family: 'Ubuntu', sans-serif;
    font-size: 2rem;
    line-height: 50px;
}

nav .brand-logo,.brand-logo{
    font-family: 'Ubuntu', sans-serif;
    font-size: 2.8rem;
    line-height: 64px;
}

img.lazy-load {
    opacity: 0;
    transition: opacity .3s ease-in;
}

.btn-group>.btn{
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

a.load-custom-range{
    padding: 0 1.2rem;
}


a.disabled{
    color: #aaa;
}
.with-padding-15{
    padding: 30px;
}
.btn-group #date-range-btn  {
    -webkit-border-radius: 5px 0 0 5px;
    -moz-border-radius: 5px 0 0 5px;
    border-radius: 5px 0 0 5px;
}


.switch label .lever {
    content: "";
    display: inline-block;
    position: relative;
    width: 40px;
    height: 22px;
    background-color: #818181;
    border-radius: 15px;
    margin-right: 10px;
    transition: background 0.3s ease;
    vertical-align: middle;
    margin: 0 16px;
}
.switch label .lever:after {
    content: "";
    position: absolute;
    display: inline-block;
    width: 21px;
    height: 21px;
    background-color: #F1F1F1;
    border-radius: 21px;
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4);
    left: 0px;
    top: 0px;
    transition: left 0.3s ease, background .3s ease, box-shadow 0.1s ease;
}

.switch label input[type=checkbox]:checked + .lever:after {
    background-color: #26a69a;
    left: 19px;
}

.dataTables_empty{
    text-align:center;
}

.dataTable span.badge.success{
    position: relative;
    background-color: #83c183;
    border-radius: 30px;
    color: white;
    padding: 3px 15px;
    font-size: 13px;
}
.dataTable span.badge.danger{
    position: relative;
    background-color: #e66;
    border-radius: 30px;
    color: white;
    padding: 3px 15px;
    font-size: 13px;
}


.sweet-alert.custom h2 {
    color: #575757;
    font-size: 20px;
    font-weight: 600;
    text-transform: none;
    line-height: 30px;
    display: block;
    margin-bottom: 30px;
}
.sweet-alert.custom p{
    line-height: 26px;
    margin-bottom: 5px;
}

.promo p{
    line-height: 24px;
    /*font-family: 'Source Code Pro';*/
}

.self div[data-placeholder]:not(:focus):not([data-div-placeholder-content]):before {
    content: attr(data-placeholder);
    float: left;
    margin-left: 5px;
    color: #fff;
}
.other div[data-placeholder]:not(:focus):not([data-div-placeholder-content]):before {
    content: attr(data-placeholder);
    float: left;
    margin-left: 0px;
    color: #c7c7c7;
}

.stats-number{
    font-size: 24px;
}

.chip i.material-icons {
    cursor: pointer;
    float: right;
    font-size: 13px;
    line-height: 32px;
    padding-left: 8px;
}


.sweet-alert .sweet-content, .sweet-alert .sweet-content p{
    font-size: 19px;
}


nav ul a {
    transition: background-color .3s;
    font-size: 1rem;
    color: #000;
    display: inline-block;
    padding: 1px 12px;
    cursor: pointer;
}

div#menu-master ul a:hover,nav#menu-master ul li.active a {
    background-color: rgba(0,0,0,0.2);
    color: #fff;
}
nav ul a:hover,nav ul a.active {
    background-color: #4c8cb2;
    color: #fff;
}

div#menu-master i{
    height: 20px;
}

.credit-remaining{
    margin-right: 0px;
}
.side-nav .credit-remaining{
    font-size: 12px;
}
.credit-remaining>span{
    margin-left: 5px;
    background-color: red;
    color: #fff;
    padding: 3px 10px;
    border-radius: 20px;
    font-size: 12px;
}
.side-nav .credit-remaining>span{
    margin-left:0;
}



hr.grey-line{
    width: 50%;
    border: 1px solid #fefefe;
}


#save-personnal-infos.btn{
    margin-right: 0;
    height: 45px;
}
.modal {
    top:5%;
}
.modal.bottom-sheet {
    top: auto;
    bottom: -100%;
    margin: 0;
    width: 100%;
    max-height: 60%;
    border-radius: 0;
    will-change: bottom, opacity;
}

.modal.bottom-sheet .modal-content {
    padding: 5px;
}

h6 {
    font-size: 1rem;
    line-height: 150%;
    margin: 0.5rem 0 0.4rem 0;
}

#covermark .hellodons{
    font-family: 'Baloo Tamma', cursive;
    color: #C3E8F1;
    margin-right: auto;
    margin-left: auto;
    opacity: .5;
}
#covermark{
    padding-top: 120px;
}

footer.page-footer{
    padding-top: 30px;
}


#chart{
    margin: 0px 20px 20px;
    min-height: 300px;

}
#gauge{
    margin: 0px 20px 20px;
    min-height: 100px;

}

nav#menu-master i, nav#menu-master [class^="mdi-"], nav#menu-master [class*="mdi-"], nav#menu-master i.material-icons {
    display: block;
    font-size: 1.2rem;
    height: 100%;
    line-height: 40px;
}

#menu-master{
    background-color: #6d9cbf;
    height: 40px;
    line-height: 38px;
    position: relative;

}
#menu-master ul{
    text-align:center;
}
#menu-master ul li{
    display: inline-block;
}


.message-info h2{
    font-family: 'Pacifico', cursive;
    font-size: 30px;
    color: #666;
}
.message-info h3{
    font-family: 'Pacifico', cursive;
    font-size: 28px;
    color: #666;
}

section{
    padding-top: 30px;
    padding-bottom: 30px;
}

.btn.lowercase{
    text-transform: inherit;
}

h1{
    margin: 0 0 5px;
    font-size: 2.3rem;
}


.label.label-info{
    background-color: #97b7f3;
}
.label.label-warning{
    background-color: #e8957c;
}
.label.label-success{
    background-color: #61c7a6;
}
.label.label-inverse{
    background-color: #333;
}

#howitwork h2{
    margin: 30px 15px 30px;
    font-size: 2rem;
}

#howitwork h3{
    margin: 30px 15px 15px;
    font-size: 1.5rem;
    color: #EE6E73;
}


.table.table-custom {
    border: 1px solid #d5d5d5;
    font-size: 15px;
    background-color: #ffffff;
}
.table.table-custom > thead > tr > th {
    background-color: #e2e4e7;
    border-bottom: 1px solid #cecece;
}
.table.table-custom td, .table.table-custom tfoot th {
    padding-left: 25px;
    padding-top: 13px;
    padding-bottom: 13px;
}
.table {
    border-radius: 2px;
}

.finance-credits{
    margin-top: 40px;
}
.finance-credits .summary{
    font-size: 12px;
    line-height: 16px;
}
.finance-credits h4{
    font-size: 1rem;
    margin: 1.3rem 0 0.912rem 0;
}
.place-order button{
    font-size: 14px;
}

#payment_detail form label{
    font-size: .8rem;
    text-transform: uppercase;
}

#payment_detail form .error{
    font-size: 12px;
    display: block;

}

#payment_detail form input{
    margin-bottom: 0;
}


.calculator{
    background: #d1e2f3;
    padding: 25px 25px;
    border-radius: 0px 0px 0px 0px;
}
.calculator h2, #payment_detail h2{
    font-size: 1rem;
    margin-top: 0;
    text-transform: uppercase;
}
.calculator h5{
    font-size: 14px;
}
.calculator table.table-receipt td {
    padding: 10px 0 5px;
}

.table.table-receipt {
    margin-top: 6px;
    margin-bottom: 15px;
}

.price-total-cell {
    font-size: 24px;
    line-height: 45px;
}
thead {
    border-bottom: 1px solid #eee;
}
table.highlight > tbody > tr:hover {
    background-color: #ffffee;
}
.table>tbody>tr>td, .table>tbody>tr>th {
    border-top: 0;
    border-bottom: none;
    vertical-align: middle;
}
.table>thead>tr>th, .table>tbody>tr>th, .table>tfoot>tr>th, .table>thead>tr>td, .table>tbody>tr>td, .table>tfoot>tr>td {
    height: 40px;
    padding: 10px 10px 5px;
}
.table>thead>tr>th, .table>tbody>tr>th, .table>tfoot>tr>th, .table>thead>tr>td, .table>tbody>tr>td, .table>tfoot>tr>td {
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: middle;

}
.table.table-receipt tr>td {
    border-bottom: 0;
}



.groupSeparatorWrapper .groupSeparator p {
    margin-bottom: 10px;
    overflow: hidden;
    text-align: center;
    font-size: 22px;
    font-family: 'Baloo Tamma', cursive;
    color: #98ccbc;
}
.groupSeparatorWrapper .groupSeparator p:before {
    margin-right: 10px;
}
.groupSeparatorWrapper .groupSeparator p:after {
    margin-left: 10px;
}

.groupSeparatorWrapper .groupSeparator p:before, .groupSeparatorWrapper .groupSeparator p:after {
    background: rgba(0,0,0,0.2);
    -webkit-box-shadow: 0 1px 0 rgba(255,255,255,0.5);
    box-shadow: 0 1px 0 rgba(255,255,255,0.5);
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 15%;
}
.baseline{

    font-size: 12px;
    line-height: 0px;
    margin: 0;
    display: block;
    text-align: center;
    font-weight: 300;
    font-family: 'Montserrat', Geneva, sans-serif;
}
.shadow-light{
    box-shadow: 0 0px 1px 0 rgba(0,0,0,0.16);
}

h1.header{
    font-family: 'Montserrat', Geneva, sans-serif;
    font-size: 36px;
    margin: 0;
}
h2.header {
    margin: 30px 0;
    font-size: 24px;
    color: #5ea58e;

}
footer.page-footer {
    margin-top: 0px;
}

.trophy #avatar-win,.trophy .profil-username, .trophy p{
    position: relative;
    z-index: 2;
    font-size: 18px;
}

strong {
    font-weight: bold;
}
#error404 h1{
    font-family: 'Pacifico', cursive;
    font-size: 10em;
    color:#EE6E73;
}
#error404 h2{
    font-family: 'Pacifico', cursive;
    color:#E4999C;
    margin-bottom: 120px;
}

nav .dropdown-button{
    height: 64px;
    position: relative;
}

.side-nav{
    background-color: #333;
}
.content-list-announces a:hover{
    text-decoration:underline;

}
.side-nav a {
    position: relative;
    color: #fff;
    display: block;
    font-size: 1rem;
    height: 64px;
    line-height: 64px;
    padding: 0 30px;
}
.side-nav a>span{
    margin-left: 25px;
}
.side-nav a.p_link>span{
    margin-left: 0;
}

.side-nav a i,.side-nav a img {
    position: absolute;
    left: 17px;
    top: 17px;

}
.personnal_parameters{
    margin-bottom: 15px;
}

.side-nav a.p_link{
    position: relative;
    color: #fff;
    display: inline-block;
    font-size: 12px;
    line-height: 16px;
    padding: 3px 8px;
    height: initial;
    cursor: pointer;
}

.badge_bt {
    display: inline-block;
    min-width: 10px;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    background-color: #e67e22;
    border-radius: 40px;
    margin: 3px 3px 3px 0px;
}

.side-nav .divider {
    height: 1px;
    overflow: hidden;
    background-color: #4A4848;
}


#howitwork .divider{
    background-color: #EE6E73;
    width: 26%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    height: 2px;
}

.side-nav a:hover {
    background-color: rgba(0,0,0,0.3);
}




#sidenav-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 120vh;
    background-color: rgba(0,0,0,0.7);
    z-index: 998;
    will-change: opacity;
}

.collapsible {
    border-top: 1px solid #efefef;
    border-right: 1px solid #efefef;
    border-left: 1px solid #efefef;
    margin: 0.5rem 0 1rem 0;
    border-bottom: none;
    box-shadow: none;
}
.collapsible-header {
    display: block;
    cursor: pointer;
    min-height: 3rem;
    line-height: 3rem;
    padding: 0 1rem;
    background-color: #fff;
    border-bottom: 1px solid #efefef;
}
.collapsible-body {
    display: none;
    border-bottom: 1px solid #efefef;
    box-sizing: border-box;
    padding: 15px;
    background-color: #fff;
}
.collapsible-body .row{
    margin-top:0;
    margin-bottom: 0;
}

.collapsible-body input[type=password].browserForm,
.collapsible-body input[type=email].browserForm{
    margin-bottom: 0;
}

#content-with-delay{
    margin-top: 20px;
}

.avatar-border {
    border: 6px solid #DEDDDD;
}
.avatar-border-white {
    border: 5px solid rgba(255, 255, 255, 0.82);
}
.avatar-profil{
    position: relative;
    width: 100px;
    margin-left: auto;
    margin-right: auto;

    -webkit-transition: background 1.5s linear;
    -moz-transition: background 1.5s linear;
    -o-transition: background 1.5s linear;
    -ms-transition: background 1.5s linear;
    transition: background 1.5s linear;
}
.avatar-profil.large{
    width: 150px;
    text-align: center;
    transition: all ease 250ms;
    position: relative;
}



.avatar-profil.large .badge-profil{
    background-color: #EE6E73;
    color: #fff;
    top: 5px;
    right: 5px;
    width: 2.5rem;
    height: 2.5rem;
    font-size: 18px;line-height: 34px;
}
.avatar-profil .badge-profil{
    position: absolute;
    display: block;
    width: 2rem;
    height: 2rem;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background-color: #FFF;
    color: #EE6E73;
    top: 0;
    right: 0;
    line-height: 28px;
    font-size: 15px;
    text-align: center;
    font-family: "Open Sans", sans-serif;
}

.avatar-profil .badge-update-avatar{
    position: absolute;
    display: block;
    width: 2.5rem;
    height: 2.5rem;
    font-size: 18px;
    padding-top: 6px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background-color: #2196F3;
    color: #fff;
    top: 100px;
    right: 5px;
    text-align: center;
    font-family: cursive;
    font-family: "Open Sans", sans-serif;
}
.date-picker-wrapper.date-range-picker19 .day.first-date-selected { background-color: red !important; }
.date-picker-wrapper.date-range-picker19 .day.last-date-selected { background-color: orange !important; }

.picker__date-display{
    display: none;
}
. no-margin{
    margin:0;
}
.no-padding{
    padding:0;
}
.section.no-pad-bot {
    padding-bottom: 0;
    background-color: #EE6E73;
}
.section.no-pad-bot-2 {
    padding-bottom: 0;
    padding-top:0;
    padding-bottom:0;
    background-color: #F7F7F7;
}
.section.no-pad-bot-3 {
    padding-bottom: 0;
    background-color: rgba(238, 110, 115, 0.84);
}

.section.no-pad-bot-3 h3{
    color: #fff;
    font-family: 'Pacifico', cursive;
}
.dropdown-content li>a, .dropdown-content li>span {
    font-size: 14px;
    color: #31646d;
    display: block;
    line-height: 22px;
    padding: 14px 16px;
    position: relative;
}

#dropdown1{
    top:64px;
}



.light-blue.lighten-1 {
    background-color: #5B98C3 !important;
}
#interested_user{
    margin-bottom:30px;
}
.chip {
    display: inline-block;
    height: 38px;
    font-size: 13px;
    font-weight: 500;
    color: rgba(0,0,0,0.6);
    line-height: 32px;
    padding: 0 12px;
    border-radius: 25px;
    background-color: #e4e4e4;
    margin: 3px;
    width: auto;
}

h1.title {
    font-size: 2.3rem;
    line-height: 110%;
    margin: 1.1rem 0 15px 0;
    font-family: 'Montserrat', Geneva, sans-serif;
    font-weight: 300;
}

h1.header {
    color:#fff;

}

.tools{
    font-size: 13px;
}
.tools p{
    margin: 0;
    font-size: 13px;
    line-height: 23px;
}
.tools .label{
    background-color: #ececec;
    padding: 2px 7px;
    border-radius: 3px;
}

.toolbox a{
    padding: 0 5px;
    color: #666;
}

.no-margin{
    margin:0;
}

.margin-top-10{
    margin-top: 15px;
}

.col.inline-block{
    display: inline-block;float:none;
}

a {
    color: #5e9aa5;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
}
body {
    margin: 0;
    padding: 0;
    width: 100%;
    font-weight: 100;
    font-family: 'Montserrat', Geneva, sans-serif;
}
nav ul.dropdown-content a:hover {
    background-color: #4c8cb2;
    color: #fff;
}

.dropdown-content{
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.16),0 1px 0px 0 rgba(0,0,0,0.12);
}
.dropdown-content{
    z-index: 1010;
}



.alert-success {
    color: #3c763d;
    background-color: #dff0d8;
}
.alert-danger {
    color: #a94442;
    background-color: #f2dede;
}
.alert-warning {
    color: #8a6d3b;
    background-color: #fcf8e3;
}
.alert.alert-info {
    color: #31708f;
    background-color: #d9edf7;
}
.alert.alert-blue {
    color: #ffffff;
    background-color: #d9edf7;
}

.alert.alert-rounded {
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
}
.alert.alert-special{
  width:50%;
    padding:10px;
    margin-left: auto;
    margin-right: auto;
}

.alert {
    text-align: center;
    padding: 15px;
    margin-bottom: 0px;
    border: none;
    border-radius: 0px;
}
.alert.left-align{
    text-align:left;
}
#countdown{
    margin-bottom: 10px;
    padding-top: 15px;
    background-color: #F1888C;
    color:#fff;
}

.card.no-shadow{
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
}

#countdown-next{
    margin-bottom: 10px;
    padding-top: 15px;
    display: none;

}
#countdown-next .muted{
    color: #ced1d7;
    font-size: 12px;
}

#countdown-next h3{
    font-size: 23px;
}

pre.prettyprint.custom {
    width: 100%;
    margin: 1em auto;
    padding: 1em;
    white-space: pre-wrap;
}

span.badge.expired:after {
    content: "";
}
span.badge.attribute {
    min-width: 3rem;
    padding: 0px 9px;
    text-align: center;
    font-weight: 300;
    font-size: 0.8rem;
    line-height: 1.6rem;
    position: relative;
    right: 0;
    box-sizing: border-box;
    vertical-align: middle;
    display: inline-block;
    color: #fff;
    background-color: #7BA1BD;
    border-radius: 20px;
    font-family: sans-serif;
}
span.badge.expired {
    min-width: 3rem;
    padding: 0px 9px;
    text-align: center;
    font-weight: 300;
    font-size: 0.8rem;
    line-height: 1.6rem;
    position: relative;
    right: 0;
    box-sizing: border-box;
    vertical-align: middle;
    display: inline-block;
    color: #fff;
    background-color: #66C65C;
    border-radius: 20px;
}
.content {
    text-align: center;
    display: inline-block;
}

.title {
    font-size: 96px;
}

/*td {
    padding: 5px 15px;
}
th {
    padding: 30px 15px 15px;
}*/
td, th {
    display: table-cell;
    text-align: left;
    vertical-align: middle;
    border-radius: 2px;
}

.switch label {
    font-size: .7rem;
    color: #111;
}
.switch.red-sw label input[type=checkbox]:checked + .lever:after {
    background-color: #ee6e73;
    left: 24px;
}
.switch.red-sw label input[type=checkbox]:checked + .lever {
    background-color: #fbb4b7;
}


.table-of-contents{
    text-align: center;
    width:100%;
}
.table-of-contents li {
    padding: 2px 0;
    display: inline-block;
}
.table-of-contents a:hover, .table-of-contents li.active a {
    color: #a8a8a8;
    padding-left:0;
    border-left:none;
    border-bottom: 1px solid #ea4a4f;
}

.table-of-contents a {
    display: inline-block;
    font-weight: 300;
    color: #757575;
    padding-left:0;
    margin-left: 20px;
    height: 1.5rem;
    line-height: 1.5rem;
    letter-spacing: .4;
    display: inline-block;
}

#list_picture .card .card-image img {
    display: block;
    border-radius: 2px 2px 0 0;
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: auto;
    margin-left: auto;
    margin-right: auto;
}

footer.page-footer{
    background-color:transparent;
}
#dropdown_export.dropdown-content{
    margin-top: 0px;
    min-width: 180px;
    max-height: 650px;
}
.dropdown-content {
    margin-top: 0;
    min-width: 290px;
    max-height: 650px;
}
#list-pic{
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}
#list-pic li{
    height: 180px;
    overflow-y: hidden;
    padding: 10px;
    margin-right: 0rem;
    display: inline-block;

}
.clearfix:after {
    content: "";
    display: table;
    clear: both;
}

#show-recap hr{
    color: #fff;
    border: none;
    height: 1px;
    border-top: 1px solid #ffffff;
    width: 4%;
}

#dialog-recap .with-padding-15{
    padding: 10px;
    padding-bottom: 20px;
}

#dialog-confirm{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 990;
    height: 100%;
    opacity: .9;
    width: 100%;
}

#detail-announce .toolbarbutton .btn,  .toolbarbutton .btn{
    color: #EE6E73;
    font-size: 12px;
}

.unactivated{
    opacity:0.5;
}
#detail-announce .description
{
    padding:15px;
}

#detail-announce .toolbarbutton,  .toolbarbutton
{
    padding:0px;
}
#detail-announce .toolbarbutton .content, .toolbarbutton .content{
    padding: 15px;
}

.modal.modal-fixed-footer .modal-footer {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    position: absolute;
    bottom: 0;
    background-color: #dddddd;
}


#detail-announce .toolbarbutton .trophy:after {
    content: "\f091";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    position: absolute;
    font-size: 244px;
    color: #F57C81;
    bottom: -78px;
    left: 50%;
    margin: 0px 0 0 -114px;
}

.etat {
    position: relative;
    z-index: 10;
    width: 6px;
    height: 6px;
    color: #fff;
    text-align: center;
    margin-top: 8px;
    border-radius: 50%;
    display: block;
    float: left;
    margin-right: 10px;
}
.etat.activated {
    background-color: #1abc9c;
}
.etat.unactivated {
    background-color: #e66;
    opacity: 1 !important;
}

#detail-announce .toolbarbutton, .toolbarbutton{
    background-color: #ee6e73;
    color: #fff;
}


#detail-announce .master-pic img{
    margin-left: auto;
    margin-right: auto;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 100%;
    width: auto;
    margin: auto !important;
    max-height: 100%;
}
#detail-announce .master-pic {
    float: none;
    position: relative;
    width: 100%;
    cursor: pointer;
    min-width: 130px;
    min-height: 120px;
    max-height: 100%;
    background: #f2f2f2;
    height: 400px;
    margin-bottom: 15px;
}
#detail-announce .thumbnails .thumb {
    overflow: hidden;
    position: relative;
    float: left;
    height: 130px;
    width: 172px;
    margin: 0 20px;
    background: no-repeat center center #f2f2f2;
    border: 2px solid #fff;
    text-align: center;
    cursor: pointer;
    min-width: 80px;
    min-height: 120px;
    max-height: 100%;
    background: #f2f2f2;
}
#detail-announce .thumbnails a{
    height: 130px;
    display: block;
}
#detail-announce .thumbnails .thumb.active{
    border: 3px solid #ee6e73;
}
#detail-announce .thumbnails .thumb img{
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 100%;
    width: auto;
    margin: auto !important;
}

#detail-announce .thumbnails {
    position: relative;
    height: 130px;
    width: auto;
    margin: 0 auto;
}
#detail-announce .thumbnails #list-picture-f {
    position: absolute;
    top: 0;
    left: 0;
    height: 130px;
    margin: 0;
    padding: 0;
    list-style: none;
}
#detail-announce section{
    padding-top: 10px;
}
#detail-announce .nav-angle {
    position: absolute;
    top: 0;
    margin: 0;
    width: 23px;
    padding: 40px 0;
    color: #f56b2a;
    cursor: pointer;
}
.carousel{
    position: relative;
    height:130px;
    margin-bottom: 0px;
}
.carousel.carousel-slider .carousel-item{
    height: auto;
}

#detail-announce .slick-prev {
    left: 0;
    z-index: 9999;
}

#detail-announce .slick-next {
    right: 27px;
    z-index: 999;
}

#detail-announce .nav-angle.posright{
    right: 0;
}
#detail-announce .nav-angle.posleft{
    left: 0;
}

section#announce-list{
    padding-top:0;
}


.btn.white, .btn-large.white{
    color: #EE6E73;
}

.chip.large {
    display: inline-block;
    height: 50px;
    font-size: 16px;
    font-weight: 500;
    color: rgba(0,0,0,0.6);
    line-height: 41px;
    padding: 0 12px;
    border-radius: 30px;
    background-color: #e4e4e4;
    margin: 3px;
    width: auto;
}
.chip.large img {
    float: left;
    margin: 0 8px 0 -12px;
    height: 43px;
    width: 43px;
    border-radius: 50%;
}

.blue{
    background-color: #4c8cb2 !important;
}
.btn.green, a.green {
    background-color: #4c8cb2;
}


.btn-floating i {
    width: inherit;
    display: inline-block;
    text-align: center;
    color: #fff;
    font-size: 1.0rem;
    line-height: 37px;
}
p.announce-text{
    font-size: 20px;
}
.announce-title{
    text-align: center;
}

.input-field .prefix {
    position: absolute;
    width: 3rem;
    font-size: 2rem;
    transition: color .2s;
    top: 17px;
}

.card.image{height: 160px;margin: 0 0 0.5rem 0;}
.card.image .card-title a{
    color:red;
}
.card.image .card-title{font-size:17px;  padding-top: 5px;}
/* label color */
.input-field label {
    color: #000;
}

input#from, input#to{
    font-size: 13px;
}


.input-field input[type=text]:focus.browserForm,
.input-field input[type=email]:focus.browserForm,
.input-field input[type=password]:focus.browserForm,
.input-field textarea:focus.browserForm{
    border: 1px solid #e0e0e0;
    box-shadow: 0 0px 8px 1px rgba(238,110,115,0.3);
    outline: none;
}

.btn.rounded{
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
}
.btn.btn-confirm-add{
    background-color: #738494;
}
.btn.btn-confirm-cancel, .btn.btn-confirm-closed{
    background-color: #ee6e73;
}
.btn.btn-confirm-complete{
    background-color: #ee6e73;
}
.btn.btn-confirm-complete:hover{
    opacity: .7 !important;
}
.btn.btn-confirm-add:hover{
    opacity: .7 !important;
}
.btn.btn-confirm-cancel:hover, .btn.btn-confirm-closed:hover{
    opacity: .7 !important;
}

.input-field input[type=text].valid, .input-field input[type=search].valid, .input-field input[type=email].valid, .input-field input[type=password].valid {
    box-shadow: none;
}
.fake-textarea{
    border: 1px solid #e0e0e0;
    padding: 5px 15px;
    border-radius: 3px;
    font-size: 14px;
    width: 100%;
    background-color: #fff;
    outline: 0;
    height:100px;
    overflow-y:auto;
    position: relative;
}


/* label focus color */
.input-field input[type=text]:focus + label,.input-field input[type=email]:focus + label,.input-field input[type=password]:focus + label {
    color: #000;
}
/* label underline focus color */
.input-field input[type=text]:focus,.input-field input[type=search]:focus, .input-field input[type=email]:focus, .input-field input[type=password]:focus, .input-field textarea:focus {
    border: 1px solid #e0e0e0;
}
/* valid color */
.input-field input[type=text].valid,.input-field input[type=search].valid, .input-field input[type=email].valid, .input-field input[type=password].valid {
    border: 1px solid #e0e0e0;

}
/* invalid color */
.input-field input[type=text].invalid,.input-field input[type=search].invalid, .input-field input[type=email].invalid, .input-field input[type=password].invalid {
    border: 1px solid #e0e0e0;
}
/* icon prefix focus color */
.input-field .prefix.active {
    color: #C3C3C3;
}

select {
    display: block;
    height: 2.6rem;
}

.btn-block{
    width: 100%;
}

#links-list-table{
    font-size: 13px;
}

#links-list-table a{
    font-size: 13px;
    color:#5ea58e;
}

#alerte-list-table_filter label, #links-list-table_filter label{
    font-size: 16px;
}

#alerte-list-table a{
    font-size: 15px;
    color:#5ea58e;
}




.dataTables_info {
    padding: 30px 0 5px;
    background-color: #fff;
    color: #333;
    text-align: center;
    border-top: none;
}
.dataTables_paginate{
    padding: 15px;
    background-color: #fff;
    color: #333;
    text-align: center;
    cursor: pointer;
}
a.paginate_button{
    color: #000;
    padding: 0 10px;
    font-size: 14px;
    cursor: pointer;
    background-color: #fff;
}
a.paginate_button.current{
    background-color: #83c1ad;
    color: #fff;
    border-radius: 3px;
}
a.paginate_button.disabled{
    color: #bbb;
}

.radius-3{
    border-radius:3px;
}

#alerte-list-table,
#groups-list-table,
#scenario-list-table{
    font-size:13px;
}


#alerte-list-table_processing,
#groups-list-table_processing,
#scenario-list-table_processing{
    position: absolute;
    left: 50%;
    top: 118px;
    background-color: #000;
    color: #fff;
    padding: 20px;
    border-radius: 3px;
    opacity: 0.8;
    margin-left: -65px;
}
#alerte-list-table_wrapper,
#links-list-table_wrapper,
#groups-list-table_wrapper,
#scenario-list-table_wrapper{
    position: relative;
}

#alerte-list-table .btn-group, #links-list-table .btn-group{
    text-align:right;
}
#alerte-list-table .btn-group a, #links-list-table .btn-group a{
   background-color: #f2f2f2;
}
#alerte-list-table_wrapper a.modal-edit-scenario{
    font-size:12px;
}


.modal .modal-footer .btn, .modal .modal-footer .btn-large, .modal .modal-footer .btn-flat{
    float: none;
}
.modal {
    box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.22);
}

#new-export .date-picker-wrapper {
    margin-top: 0;background-color: #fafafa;
}

.date-picker-wrapper .drp_top-bar{
    text-align:left;
}

#reportrange .date-picker-wrapper {
    position: relative;
    z-index: 1;
    border: none;
    padding: 5px 0;
    line-height: 20px;
    color: #aaa;
    box-shadow: none;
    box-sizing: initial;
    background-color: #f8f8f9;
    margin-top: 20px;
}
#reportrange .date-picker-wrapper .month-wrapper table .week-name {
    height: 20px;
    line-height: 20px;
    font-weight: 100;
     text-transform: inherit;
}
#reportrange .date-picker-wrapper .month-name {
    text-transform: inherit;
}


#fileProgress{
    margin-top: 25px;
    position: relative;
}

#fileProgress #fileText{
    position: relative;
    top: 0;
    left: 10px;
    color: #fff;
    font-size: 14px;
}

#fileProgress #fileText em{
    font-style: normal;
}

#fileProgress .progress {
    position: relative;
    height: 23px;
    display: block;
    width: 100%;
    background-color: #ffffff;
    border-radius: 30px;
    margin: 0.5rem 0 1rem 0;
    overflow: hidden;
}
#fileProgress .progress .determinate {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: #387fb3;
    transition: width .3s linear;
}

.btn.btn-small{
    padding: 0 0.8rem;
    font-size: 13px;
    text-transform: inherit;
    z-index: 1;
}

.btn.btn-small i {
    font-size: .9rem;
    line-height: inherit;
}

#reportrange input[type=text].browserForm{
    border: 1px solid #e0e0e0;
    padding: 5px 10px;
    border-radius: 3px;
    height: 24px;
    font-size: 14px;
    width: 80%;
    background-color: #fff;
    margin-bottom: 0;
    outline:0;
}


input[type=text].browserForm.taginput{
    border: none;
    box-shadow: none;
    outline: none;
    background-color: transparent;
    padding: 5px;
    margin: 0;
    width: auto !important;
    max-width: inherit;
    font-size: 13px;
    height: 23px;
    
}

#personnal-member-form input[type=text].browserForm,
#personnal-member-form input[type=password].browserForm,
#personnal-member-form input[type=email].browserForm,
#personnal-member-form input[type=url].browserForm,
#personnal-member-form input[type=time].browserForm,
#personnal-member-form input[type=date].browserForm,
#personnal-member-form input[type=datetime].browserForm,
#personnal-member-form input[type=datetime-local].browserForm,
#personnal-member-form input[type=tel].browserForm,
#personnal-member-form input[type=number].browserForm,
#personnal-member-form input[type=search].browserForm{
    border: 1px solid #e0e0e0;
    padding: 5px 10px;
    border-radius: 3px;
    height: 35px;
    font-size: 15px;
    width: 100%;
    background-color: #fff;
    margin-bottom: 0;
    outline:0;
}

input:not([type]):focus:not([readonly]).browserForm,
input[type=text]:focus:not([readonly]).browserForm,
input[type=password]:focus:not([readonly]).browserForm,
input[type=email]:focus:not([readonly]).browserForm,
input[type=url]:focus:not([readonly]).browserForm,
input[type=time]:focus:not([readonly]).browserForm,
input[type=date]:focus:not([readonly]).browserForm,
input[type=datetime]:focus:not([readonly]).browserForm,
input[type=datetime-local]:focus:not([readonly]).browserForm,
input[type=tel]:focus:not([readonly]).browserForm,
input[type=number]:focus:not([readonly]).browserForm,
input[type=search]:focus:not([readonly]).browserForm,
textarea.materialize-textarea:focus:not([readonly]).browserForm {
    border-bottom: 1px solid #e0e0e0;
     box-shadow: none;
}

input[type=search].search-filter{
    border: 1px solid #e0e0e0;
    padding: 5px 10px;
    border-radius: 3px;
    height: 33px;
    font-size: 15px;
    background-color: #fff;
    margin-bottom: 0;
    outline:0;
    box-sizing: border-box;
}

.dataTables_length select{
    height: 41px;
}


#personnal-member-form .row{
    margin-bottom: 20px;
}
#personnal-member-form textarea.browserForm{
    height: 80px;
    outline:0;
}


input[type=text].browserForm,
input[type=password].browserForm,
input[type=email].browserForm,
input[type=url].browserForm,
input[type=time].browserForm,
input[type=date].browserForm,
input[type=datetime].browserForm,
input[type=datetime-local].browserForm,
input[type=tel].browserForm,
input[type=number].browserForm,
input[type=search].browserForm{
    border: 1px solid #e0e0e0;
    padding: 5px 15px;
    border-radius: 3px;
    height: 42px;
    font-size: 15px;
    width:100%;
    background-color: #fff;
    outline:0;
}

textarea.browserForm {
    border: 1px solid #e0e0e0;
    padding: 5px 15px;
    border-radius: 3px;
    height: 130px;
    font-size: 15px;
    width: 100%;
    background-color: #fff;
    outline:0;
}





select{
    border:1px solid #e0e0e0;
}

input:not([type]), input[type=text], input[type=password], input[type=email], input[type=url], input[type=time], input[type=date], input[type=datetime], input[type=datetime-local], input[type=tel], input[type=number], input[type=search], textarea.materialize-textarea {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #9e9e9e;
    border-radius: 0;
    outline: none;
    height: 3rem;
    width: 100%;
    font-size: 1.3rem;
    margin: 0 0 15px 0;
    padding: 0;
    box-shadow: none;
    box-sizing: content-box;
    transition: all 0.3s;
}
.input-field label.active {
    font-size: 1rem;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
}
.input-field label {
    color: #9e9e9e;
    position: relative;
     top: 0;
    left: 0;
    font-size: 1rem;
    cursor: text;
     transition: .2s ease-out;
    margin-bottom: 5px;
    display: block;
}
.select-wrapper input.select-dropdown {
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #9e9e9e;
    outline: none;
    height: 3rem;
    line-height: 3rem;
    width: 100%;
    font-size: 1.3rem;
    margin: 0 0 15px 0;
    padding: 0;
    display: block;
}

.btn-search {
    background-color: #66C65C;
    border: none;
    border-radius: 2px;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    outline: 0;
    padding: 0 1rem;
    text-transform: uppercase;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
}

#socialShareBloc{


}

#socialShare{
    text-align: center;
    margin: 0px auto;
}
#socialShare li {
    display: inline-block;
    width: 45px;
    height: 45px;
    margin: 15px 0px;
    list-style: none;
}

#socialShare div.facebook{
    background-color:#3B5998;
    color:#fff;
}
#socialShare div.twitter{
    background-color:#5EA9DD;
    color:#fff;
}
#socialShare div.google{
    background-color:#DC4C41;
    color:#fff;
}
#socialShare div.linkedin{
    background-color:#007BB6;
    color:#fff;
}

#socialShare li div {
    width: 40px;
    height: 40px;
    color: #74d4b3;
    font-size: 1.5em;
    text-align: center;
    line-height: 40px;
    background-color: #fff;
    transition: all 0.5s ease;
}

#socialShare li div:hover, .animate_rotate {
    transform: rotate(360deg);
    border-radius: 100px;
}


li.conversation.user-conv,li.conversation.bot-conv{
    padding: 15px;
    background-color: #f1f1f1;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}
.self.user-conv .avatar{
    margin-left: 0;
}
.other.bot-conv .avatar{
    margin-right: 0;
}


.tabs .tab{
    text-transform: inherit;
}

.tabs.mini-tabs{
    height: 44px;
    font-size: 11px;
}
.content_toolbar_bot{
    padding-top: 15px;
}

.tabs{
    overflow-x: hidden;
}

.tabs-custom {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    overflow-x: auto;
    overflow-y: hidden;
    height: 48px;
    background-color: #fff;
    margin: 0 auto;
    width: 100%;
    white-space: nowrap;
    box-shadow: 0 1px 2px rgba(0,0,0,0.2);
}
.tabs-custom .tab {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    display: block;
    float: left;
    text-align: center;
    line-height: 48px;
    height: 48px;
    padding: 0;
    margin: 0;
    text-transform: none;
    text-overflow: ellipsis;
    overflow: hidden;
    letter-spacing: .8px;
    width: 15%;
    min-width: 80px;
}
.tabs-custom .tab a {
    color: #ee6e73;
    display: block;
    width: 100%;
    height: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    transition: color .28s ease;
}
.tabs-custom .tab a.active {
    border-bottom: 1px solid #ee6e73;
}
#sliderrange.noUi-horizontal {
    height: 3px;
    margin-top: 10px;
}


#sliderrange.noUi-target .range-label {
    position: absolute;
    height: 40px;
    width: 40px;
    top: -26px;
    left: -6px;
    background-color: #EE6E73;
    border-radius: 50%;
    transition: border-radius .25s cubic-bezier(0.215, 0.610, 0.355, 1.000),
    transform .25s cubic-bezier(0.215, 0.610, 0.355, 1.000);
    transform: scale(.5) rotate(-45deg);
    transform-origin: 50% 100%;
}
#sliderrange.noUi-target .noUi-active .range-label {
    border-radius: 30px 30px 30px 0;
    transform: rotate(-45deg) translate(23px, -21px);

}
#sliderrange .range-label span {
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 14px;
    transform: rotate(45deg);
    opacity: 0;
    position: absolute;
    top: 8px;
    left: 0px;
    transition: opacity .25s cubic-bezier(0.215, 0.610, 0.355, 1.000);
}
#sliderrange .noUi-active .range-label span {
    opacity: 1;
}

#sidebar{
    background-color: #f5f5f5;
    padding: 20px 0;
}

.reset{
    color: #d1d1d1;
    transition: background 0.4s;
    position: absolute;
    bottom: 20px;
    right: 18px;
    display: none;
}




.box-shadow-z0, .box-shadow-z0 .box, .box-shadow-z0 .box-color {
    box-shadow: 0 0 1px rgba(0,0,0,.15);
}
.box, .box-color {
    background-color: #fff;
    position: relative;
    margin-bottom: 1.5rem;
}

.box-header {
    position: relative;
}

.box-header h3 {
    font-size: 16px;
    margin-top: 3px;
    margin-bottom: 3px;
    font-family: "Source Code Pro", "Consolas", "Monaco", "Bitstream Vera Sans Mono", "Courier New", Courier, monospace;
}


.box-body, .box-footer, .box-header {
    padding: 1rem;
}



.b-l {
    border-left: 1px solid rgba(120,130,140,.13);
}
.m-l, .m-x {
    margin-left: 1rem!important;
}
.streamline .sl-icon:before, .streamline .sl-item:before, .streamline:after {
    content: '';
    position: absolute;
    border-color: inherit;
    border-width: 3px;
    border-style: solid;
    border-radius: 50%;
    width: 7px;
    height: 7px;
    margin-left: -4px;
    top: 6px;
    left: 0;
}

.streamline .sl-icon:before {
    width: 20px;
    height: 20px;
    border-width: 10px;
    margin: 0!important;
    top: 0!important;
}
.sl-content {
    margin-left: 24px;
    padding-bottom: 16px;
}
.text-muted {
    color: inherit;
    opacity: .6;
}
.sl-date {
    font-size: .85em;
}
.sl-icon {
    position: absolute;
    left: -10px;
    z-index: 1;
    border: inherit;
}
.sl-left + .sl-content {
    margin-left: 36px;
}

.sl-left {
    float: left;
    z-index: 1;
    margin-left: -20px;
    margin-bottom: 24px;
}

.streamline:after {
    top: auto;
    bottom: 0;
}
.sl-item:after {
    top: 2px;
    bottom: auto;
}
.sl-item::after {
    display: table;
    clear: both;
}
.sl-item::after, .ui-switch i:before {
    content: "";
}
.streamline {
    position: relative;
    border-color: rgba(120,130,140,.13);
}
.b-success {
    border-color: #6cc788;
}
.sl-item {
    border-color: rgba(120,130,140,.13);
    padding-bottom: 1px;
}
.sl-item, .sl-left, .ui-switch {
    position: relative;
}

.b-info {
    border-color: #6887ff;
}
.b-warning {
    border-color: #f77a99;
}




#date-page .phone-device {
    width: 100%;
    max-width: 401px;
    height: 529px;
    border: 2px solid rgb(226, 226, 226);
    border-radius: 15px 15px 0 0;
    position: relative;
    color: #171616;
    background-color: transparent;
}

#date-page .phone-device-camera {
    border: inherit;
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    top: 23px;
    left: 15%;
    border-color: #e2e2e2;
}

#date-page .phone-device-speaker {
    border: inherit;
    position: absolute;
    width: 100px;
    height: 10px;
    border-radius: 5px;
    top: 26px;
    left: 50%;
    margin-left: -50px;
    border-color: #e2e2e2;
}

#date-page .phone-device-screen-container {
    border: inherit;
    position: absolute;
    top: 60px;
    width: 90%;
    left: 5%;
    height: 467px;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
    background-color: transparent;
}

#date-page .sms-conversation-bubble {
    float: left;
    margin-left: 15px;
    margin-bottom: 15px;
    width: 100%;
    position: relative;
}

#date-page .sms-conversation-bubble p {
    margin: 0;
    color: #2c3645;
    background: #E8E8E8;
    padding: 15px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
}

#date-page .sms-conversation-bubble:after {
    content: '';
    width: 15px;
    height: 15px;
    border: 10px solid rgba(0,0,0,0);
    border-left: 10px solid #E8E8E8;
    position: absolute;
    right: -20px;
    top: 20px;
}

#date-page .sms-conversation {
    margin-bottom: 15px;
    text-align: left;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    padding: 60px 30px 30px;
}

#date-page .phone-device-status-bar {
    color: inherit;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 40px;
    padding: 10px;
    font-size: 14px;
    z-index: 999;
    color: #e2e2e2;
    background-color: transparent;
}

#date-page .phone-device-status-bar-dot {
    width: 7px;
    height: 7px;
    background: #e2e2e2;
    display: inline-block;
    border-radius: 50%;
    float: left;
    margin: 6px 4px 5px 0;
}







/*#announce-form .reset{
    top: 35px;
}*/
.reset.display{
    display: block;
}



#timeline-practicalcase-section #cd-timeline {
    position: relative;
    padding: 0 0;
    margin-top: 2em;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
}
#timeline-practicalcase-section .cd-timeline-block {
    position: relative;
    margin: 4.5em 0;
}



#timeline-practicalcase-section .cd-timeline-content .cd-icon {
    position: absolute;
    width: 100%;
    left: 145%;
    top: -54px;
}
#timeline-practicalcase-section .cd-timeline-content h2.timeline-title {
    padding-top: 0;
    margin-top: 0;
    color: #ee6e73;
    font-size: 13px;
    font-size: 2.3rem;
    line-height: 32px;
}
#timeline-practicalcase-section h2 {
    margin-top: 0;
    font-size: 16px;
    font-size: 1.6rem;
    font-family: 'Pacifico', cursive;
    color: #4b4b4b;
    padding: 15px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    display: block;
}

.cd-timeline-content.is-hidden {
    visibility: hidden;
}

#timeline-practicalcase-section .cd-timeline-content {
    position: relative;
    margin-left: 0px;
    padding: 0;
    width: 45%;
}
#timeline-practicalcase-section .cd-timeline-img {
    position: absolute;
    top: -10px;
    width: 50px;
    height: 50px;
    left: 50%;
    margin-left: -25px;
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
}

#timeline-practicalcase-section .cd-timeline-img>div{
    background-color: #ee6e73;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    text-align: center;
    line-height: 2rem;
    font-size: 2rem;
}
#timeline-practicalcase-section .cd-timeline-img>div>p {
    margin-top: 0;
    line-height: 2rem;
    color: #fff;
    line-height: 2.9rem;
    font-family: 'Pacifico', 'cursive';
}

#timeline-practicalcase-section .cd-timeline-img>div>p i {
    line-height: 47px;
}


#timeline-practicalcase-section #cd-timeline::before {
    content: '';
    position: absolute;
    top: 0;
    left: 18px;
    height: 90%;
    width: 4px;
    border-left: 5px dashed #ee6e73;
}
#timeline-practicalcase-section #cd-timeline::before {
    left: 50%;
    margin-left: -2px;
}

#timeline-practicalcase-section .cd-timeline-img {
    position: absolute;
    top: -10px;
    width: 50px;
    height: 50px;
}


#timeline-practicalcase-section .cd-timeline-img {
    width: 50px;
    height: 50px;
    left: 50%;
    margin-left: -25px;
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
}


#timeline-practicalcase-section .cd-timeline-block:nth-child(even) .cd-timeline-content.bounce-in {
    -webkit-animation: cd-bounce-2-inverse 0.6s;
    -moz-animation: cd-bounce-2-inverse 0.6s;
    animation: cd-bounce-2-inverse 0.6s;
}
#timeline-practicalcase-section .cd-timeline-block:nth-child(even) .cd-timeline-content {
    float: right;
}
#timeline-practicalcase-section .cd-timeline-content.bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-2 0.6s;
    -moz-animation: cd-bounce-2 0.6s;
    animation: cd-bounce-2 0.6s;
}

#timeline-practicalcase-section .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-icon {
    left: auto;
    right: 145%;
    text-align: right;
}
#timeline-practicalcase-section .cd-timeline-content .cd-icon {
    position: absolute;
    width: 100%;
    left: 145%;
    top: -54px;
}

.cd-timeline-content.is-hidden {
    visibility: hidden;
}
.cd-timeline-content.bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-2 0.6s;
    -moz-animation: cd-bounce-2 0.6s;
    animation: cd-bounce-2 0.6s;
}
.cd-timeline-block:nth-child(even) .cd-timeline-content.bounce-in {
    -webkit-animation: cd-bounce-2-inverse 0.6s;
    -moz-animation: cd-bounce-2-inverse 0.6s;
    animation: cd-bounce-2-inverse 0.6s;
}
.cd-timeline-img.bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-1 0.6s;
    -moz-animation: cd-bounce-1 0.6s;
    animation: cd-bounce-1 0.6s;
}
.cd-timeline-img.is-hidden {
    visibility: hidden;
}
.cd-timeline-img.bounce-in {
    visibility: visible;
    animation: cd-bounce-1 0.6s;
}


.right-align {
    text-align: right;
}

#circles-slider{
    position: relative;
}
#circles-slider.ui-slider {
    border-radius: 20px;
    background: #434d5a;
    border: none;
    height: 10px;
    margin: 1em 1.5em 1.5em; }

#circles-slider .ui-slider-handle {
    border-radius: 23px;
    height: 23px;
    width: 23px;
    top: -7px;
    margin-left: -11px;
    border: 2px solid #96c03d;
    background-color: #96c03d;
    display: block;
    position: absolute;
}

.ui-slider-pips .ui-slider-pip-label .ui-slider-label {
    display: block;
    margin-top: 12px;
}

#circles-slider .ui-slider-pip {
    top: 3px; }

#circles-slider .ui-slider-pip .ui-slider-line {
    width: 4px;
    height: 4px;
    border-radius: 4px;
    margin-left: -2px;
    background: #fffaf7; }

#circles-slider .ui-slider-pip.ui-slider-pip-last,
#circles-slider .ui-slider-pip.ui-slider-pip-first {
    top: -7px; }

#circles-slider .ui-slider-pip.ui-slider-pip-last .ui-slider-line,
#circles-slider .ui-slider-pip.ui-slider-pip-first .ui-slider-line {
    display: none; }

#circles-slider .ui-slider-pip.ui-slider-pip-last .ui-slider-label,
#circles-slider .ui-slider-pip.ui-slider-pip-first .ui-slider-label {
    margin: 0; }

#circles-slider .ui-slider-pip.ui-slider-pip-first .ui-slider-label {
    left: -2em;
    text-align: right; }

#circles-slider .ui-slider-pip.ui-slider-pip-last .ui-slider-label {
    left: 2em;
    text-align: left; }

#circles-slider .ui-slider-pip.ui-slider-pip-selected-initial {
    font-weight: normal; }

#circles-slider .ui-slider-pip.ui-slider-pip-selected {
    font-weight: bold; }

#circles-slider .ui-slider-pip.ui-slider-pip-selected,
#circles-slider .ui-slider-pip.ui-slider-pip-selected-initial {
    color: #434d5a; }



footer.page-footer .footer-copyright{
    color: #000;font-size:12px;
    padding-left: 260px;
    background-color: transparent;
}


table .label, .message-text .label {
    display: inline;
    padding: .3em 1.2em .3em;
    font-size: 12px;
    font-weight: bold;
    line-height: 1;
    color: #ffffff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 20px;
}

.label-inverse {
    background-color: #4b4d5c;
}

.panel {
    margin-bottom: 20px;
    background-color: #fff;
    border: none;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 3px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.04);
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.04);
}

.panel-heading h4 {
    padding-left: 0em;
    font-size: 100%;
}

table.striped > tbody > tr:nth-child(odd) {
    background-color: #FbFbFb;
}

.btn i, .btn-large i, .btn-floating i, .btn-large i, .btn-flat i {
    font-size: 1rem;
    line-height: inherit;
}


.table.large-padding>thead>tr>td,
.table.large-padding>tbody>tr>td,
.table.large-padding>tfoot>tr>td{
    padding-right: .5em;
    padding-left: .5em;
    padding-top: 1.64em;
    padding-bottom: 1.64em;
}




.panel-default > .panel-heading {
    color: #eaebee;
    background-color: #3F4450;
}

.panel-heading {
    background-color: #f8f9fa;
    padding: 12px 12px;
    border-bottom: 0;
}

.panel.type-paiement, .panel.address-invoice, .panel.type-card {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid #d5d5d5;
}

.panel.type-paiement .panel-body, .address-invoice .panel-body {
    padding: 19px 12px;
    min-height: 152px;
}

.type-paiement .table tbody>tr>td {
    padding: 13px;
    line-height: 1.428571429;
    vertical-align: middle;
    border-top: 1px solid #e5e6ea;
}

.no-margin-bottom{
    margin-bottom: 0;
}

.sweet-confirm{
    margin-right: 5px;
}

.panel-heading h4 {
    padding-left: 0em;
    margin: 0;
}
h4 {
    font-size: 90%;
}
.panel.no-shadow {
    box-shadow: none;
    -moz-box-shadow: none;
    -o-box-shadow: none;
    -webkit-box-shadow: none;
    border-bottom: 0;
}
.panel-info-solid {
    /* border-bottom: 5px solid #599898; */
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.panel-body-only {
    -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
}
.no-padding {
    padding: 0!important;
}

.panel-body-only {
    -webkit-box-shadow: 0 2px 2px rgba(0,0,0,0.2);
    box-shadow: 0 2px 2px rgba(0,0,0,0.2);
    padding: 8px;
}
.panel-body {
    padding: 20px 12px;
    background-color: #ffffff;
}


a span.medium-text {
    color: white;
}
.medium-text {
    font-size: 1em;
}
.text-white{
    color: #fff;
}

.panel-info-solid .panel-body {
    background-color: #00B6B7;
    color: #fff;
    min-height: 91px;
}
.panel-body.button-panel {
    padding: 0;
}
.panel-info-solid a, .panel-secondary-solid a, .panel-warning-solid a, .panel-primary-solid a {
    line-height: 88px;
    display: block;
}


.panel-secondary-solid .panel-body {
    background-color: #9f3e69;
    color: #fff;
    min-height: 91px;
}
.panel-body.button-panel {
    padding: 0;
}



.panel-warning-solid .panel-body {
    background-color: #3D3D3C;
    color: #fff;
    min-height: 91px;
}
.panel-body.button-panel {
    padding: 0;
}


.panel-info-solid.disabled a, .panel-secondary-solid.disabled a, .panel-warning-solid.disabled a, .panel-primary-solid.disabled a{
    line-height: 20px;
    padding-top: 26px;
}

.panel-info-solid.disabled .fa-chevron-right, .panel-secondary-solid.disabled .fa-chevron-right, .panel-warning-solid.disabled .fa-chevron-right, .panel-primary-solid.disabled .fa-chevron-right {
    display: none;
}
.panel-info-solid.disabled, .panel-secondary-solid.disabled, .panel-warning-solid.disabled, .panel-primary-solid.disabled {
    opacity: 0.3;
}

@media only screen and (min-width: 768px) {
    .info-box-icon {
        border-top-left-radius: 2px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 2px;
        display: block;
        float: left;
        height: 90px;
        width: 90px;
        text-align: center;
        font-size: 28px;
        line-height: 90px;
        background: rgba(0, 0, 0, 0.2);
    }
}


.form-control-static {
    margin-bottom: 0;
    padding-top: 13px;
    padding-left: 10px;
}

.form-control-static {
    min-height: 34px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-bottom: 0;
}


@keyframes cd-bounce-1 {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }

    60% {
        opacity: 1;
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

@-webkit-keyframes cd-bounce-2 {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateX(20px);
    }

    100% {
        -webkit-transform: translateX(0);
    }
}
@-moz-keyframes cd-bounce-2 {
    0% {
        opacity: 0;
        -moz-transform: translateX(-100px);
    }

    60% {
        opacity: 1;
        -moz-transform: translateX(20px);
    }

    100% {
        -moz-transform: translateX(0);
    }
}
@keyframes cd-bounce-2 {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-100px);
        -moz-transform: translateX(-100px);
        -ms-transform: translateX(-100px);
        -o-transform: translateX(-100px);
        transform: translateX(-100px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateX(20px);
        -moz-transform: translateX(20px);
        -ms-transform: translateX(20px);
        -o-transform: translateX(20px);
        transform: translateX(20px);
    }

    100% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
}
@-webkit-keyframes cd-bounce-2-inverse {
    0% {
        opacity: 0;
        -webkit-transform: translateX(100px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateX(-20px);
    }

    100% {
        -webkit-transform: translateX(0);
    }
}
@-moz-keyframes cd-bounce-2-inverse {
    0% {
        opacity: 0;
        -moz-transform: translateX(100px);
    }

    60% {
        opacity: 1;
        -moz-transform: translateX(-20px);
    }

    100% {
        -moz-transform: translateX(0);
    }
}
@keyframes cd-bounce-2-inverse {
    0% {
        opacity: 0;
        -webkit-transform: translateX(100px);
        -moz-transform: translateX(100px);
        -ms-transform: translateX(100px);
        -o-transform: translateX(100px);
        transform: translateX(100px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateX(-20px);
        -moz-transform: translateX(-20px);
        -ms-transform: translateX(-20px);
        -o-transform: translateX(-20px);
        transform: translateX(-20px);
    }

    100% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }
}



#toast-container .rounded{
    border-radius: 30px;
    box-shadow: none;
}




@media only screen and (min-width: 993px){
    #toast-container {
        top: 2% !important;
        right: 2% !important;
        max-width: 86%;
        z-index: 9999 !important;
    }
}





@media only screen and (max-width: 992px) {
    #sliderrange{
        margin-bottom: 30px;
    }
    .btn-search {

        height: 50px;
        line-height: 50px;

    }

}


@media only screen and (max-width: 768px) {
    .btn-social{
        font-size: 11px;
    }
    #detail-announce .master-pic{
        height: 300px;

    }


}

@media only screen and (max-width: 600px) {
    #sidebar{
        margin-top: 40px;
    }


    #timeline-practicalcase-section .cd-timeline-content .cd-icon {
        position: absolute;
        width: 60%;
        left: 145%;
        top: -27px;
    }
    #timeline-practicalcase-section .cd-timeline-content h2.timeline-title {
        font-size: 13px;
        font-size: 1.6rem;
        line-height: 25px;
    }


}
@media only screen and (max-width: 400px) {

    #countdown p.text-muted{
        font-size: 11px;
    }
    .tabs-custom .tab a{
        font-size: 10px;
    }
}
@media (max-width: 480px){
    #detail-announce .master-pic{
        height: 250px;
    }
}





